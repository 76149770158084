export const tariffsProps = [
  'recognition',
  'watch_tournaments',
  'analysis',
  'watch_game',
  'share',
  'connect_broadcast',
  'create_broadcast',
  'bot_training',
];

export const tariffsItems = {
  free: ['recognition', 'watch_tournaments', 'analysis'],
  '1_month': [
    'recognition',
    'watch_tournaments',
    'analysis',
    'watch_game',
    'share',
    'connect_broadcast',
    'create_broadcast',
    'bot_training',
  ],
  '1_year': [
    'recognition',
    'watch_tournaments',
    'analysis',
    'watch_game',
    'share',
    'connect_broadcast',
    'create_broadcast',
    'bot_training',
  ],
};
