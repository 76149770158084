import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import cn from 'classnames';

import Link from 'next/link';
import { mainPageAnchors } from 'helpers/anchors';

import styles from './Ambassadors.module.scss';
import Slider from '../Slider';
import { SwiperSlide } from 'swiper/react';
import {
  AmbassadorsItem as IAmbassadorsItem,
  getAmbassadors,
} from 'services/ambassadorsService';
import { RootState } from 'stores/store';
import { useSelector } from 'react-redux';
import { IUserStore } from 'stores/userStore';
import { useRouter } from 'next/router';

type AmbassadorsProps = {
  ambassadorsData?: IAmbassadorsItem[];
};

const Ambassadors: React.FC<AmbassadorsProps> = ({ ambassadorsData }) => {
  const { t } = useTranslation('ambassadors');
  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const [ambassadors, setAmbassadors] = useState(ambassadorsData);

  useEffect(() => {
    const ambassadorsItemsResponse = async () => {
      const response = await getAmbassadors({
        locale: currentLanguage,
        geo: geoDataStored,
      });

      if (response?.data?.ambassadors) {
        setAmbassadors(response?.data?.ambassadors);
      }
    };

    ambassadorsItemsResponse();
  }, [geoDataStored, currentLanguage]);

  return (
    <section
      id={mainPageAnchors.ambassadors}
      className={cn('content', styles.ambassadors)}
    >
      {ambassadorsData?.length && (
        <Slider
          title={t('ambassadors.title')}
          spaceBetween={15}
          breakpoints={{
            1: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            480: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            992: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 18,
            },
            1280: {
              slidesPerView: 5,
              slidesPerGroup: 5,
            },
          }}
          className={styles.media__slider}
          headerAdditionalChildren={
            <>
              <p className={styles.ambassadors__subheading}>
                {t('ambassadors.community')}
                <br />
                <Link href={process.env.NEXT_PUBLIC_WHATSAPP_LINK}>
                  <a className={cn('link', 'text-green')} target='_blank'>
                    {t('ambassadors.contact_us')}
                  </a>
                </Link>
              </p>
            </>
          }
        >
          {ambassadors?.length &&
            ambassadors.map(
              ({ id, full_name, summary, image_url, country_flag_url }, i) => (
                <SwiperSlide className={styles.ambassadors__item} key={i}>
                  <div className={styles.ambassadors__image}>
                    <picture>
                      <img
                        src='/images/ambassadors/ambassadors-placeholder.jpg'
                        data-src={image_url}
                        alt={t(full_name)}
                        className={cn('img', 'lazyload')}
                      />
                    </picture>
                  </div>
                  <div className={styles.ambassadors__name}>{t(full_name)}</div>
                  <div className={styles.ambassadors__place}>
                    <Trans>{t(summary)}</Trans>
                    <img
                      className={styles.ambassadors__flag}
                      src={country_flag_url}
                      alt={id + '_country-flag'}
                    />
                  </div>
                </SwiperSlide>
              )
            )}
        </Slider>
      )}
    </section>
  );
};

export default Ambassadors;
