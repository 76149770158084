import React, { useRef } from 'react';
import cn from 'classnames';
import { useTranslation, Trans } from 'next-i18next';
import { FaqItemElems } from 'interfaces/Faq/Faq';
import { SwiperSlide } from 'swiper/react';

import Link from 'next/link';
import FaqItem from './FaqItem';

import styles from './Faq.module.scss';
import VerticalSlider from '../VerticalSlider';
import { chunkArray } from 'helpers/chunkArray';
import { socialLinks } from 'helpers/socialLinks';
import { links } from 'helpers/constants/links';
import SeeMoreLink from '../SeeMoreLink';

const Faq: React.FC = () => {
  const { t } = useTranslation('faq');

  const items: FaqItemElems[] = t('faq.questions', { returnObjects: true });
  const itemsRef = useRef<Map<number, HTMLDivElement>>(null);

  const getMap = () => {
    if (!itemsRef.current) itemsRef.current = new Map();
    return itemsRef.current;
  };

  return (
    <section id='faq' className={cn(styles.faq, ['content'])}>
      <VerticalSlider
        heading={t('faq.title')}
        text={
          <p className={styles.faq__description}>
            <Trans>{t('faq.description')}</Trans>
            <Link href={socialLinks.mail}>
              <a className='link text-green'>{t('faq.descriptionLink')}</a>
            </Link>
          </p>
        }
        spaceBetween={56}
        getActiveSlideHeight={index => getMap().get(index)?.offsetHeight}
        link={<SeeMoreLink link={`/${links.faq}`} text={t('faq.see_more')} />}
      >
        {chunkArray(items, 4).map((items, index) => {
          return (
            <SwiperSlide key={index}>
              <div
                ref={node => {
                  const map = getMap();
                  if (node) {
                    map.set(index, node);
                  } else map.delete(index);
                }}
              >
                {items.map(props => (
                  <FaqItem key={props.title} {...props} />
                ))}
              </div>
            </SwiperSlide>
          );
        })}
      </VerticalSlider>
    </section>
  );
};

export default Faq;
