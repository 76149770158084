import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';

type AmbassadorsDataResponse = {
  ambassadors: AmbassadorsItem[];
};
export interface AmbassadorsItem {
  id: number;
  full_name: string;
  summary: string;
  image_url: string;
  country_flag_url: string;
}

export const getAmbassadors = async ({
  locale,
  geo,
}): Promise<AxiosResponse<AmbassadorsDataResponse>> => {
  try {
    const response = await httpClient.get<AmbassadorsDataResponse>(
      '/ambassadors',
      {
        params: {
          locale,
          geo,
        },
      }
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};
