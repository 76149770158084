import React from 'react';
import cn from 'classnames';
import { Trans } from 'next-i18next';
import { FaqItemData } from 'interfaces/Faq/Faq';

import Link from 'next/link';

import styles from '../Faq.module.scss';
import { links } from 'helpers/constants/links';

interface FaqItemProps extends FaqItemData {
  isLarge?: boolean;
}

const FaqItem: React.FC<FaqItemProps> = ({ title, answer, isLarge }) => {
  return (
    <div className={cn(styles.faq__item, { [styles.faq__itemLarge]: isLarge })}>
      <div className={cn(styles.faq__itemTitle)}>
        <span>{title}</span>
      </div>
      <div className={styles.faq__itemText}>
        <div className={styles.faq__itemTextInner}>
          {answer.map((answer, a) => {
            let text = <Trans key={a}>{answer.text}</Trans>;

            if (answer.type) {
              switch (answer.type) {
                case 'organizersPage':
                  text = (
                    <Link key={a} href='/organizers'>
                      <a className={cn('link', 'text-green', 'link-underline')}>
                        <Trans>{answer.text}</Trans>
                      </a>
                    </Link>
                  );
                  break;

                case 'linkMail':
                  text = (
                    <Link key={a} href='mailto:hello@idchess.com'>
                      <a className={cn('link', 'text-green', 'link-underline')}>
                        <Trans>{answer.text}</Trans>
                      </a>
                    </Link>
                  );
                  break;

                case 'linkWhatsapp':
                  text = (
                    <Link key={a} href={process.env.NEXT_PUBLIC_WHATSAPP_LINK}>
                      <a
                        className={cn('link', 'text-green', 'link-underline')}
                        target='_blank'
                      >
                        <Trans>{answer.text}</Trans>
                      </a>
                    </Link>
                  );
                  break;

                case 'appLink':
                  text = (
                    <a
                      className={cn('link', 'text-green', 'link-underline')}
                      href='/app'
                      target='_blank'
                      key={a}
                    >
                      <Trans>{answer.text}</Trans>
                    </a>
                  );
                  break;

                case 'linkTariffs':
                  text = (
                    <Link key={a} href={`/${links.shop}`}>
                      <a className={cn('link', 'text-green', 'link-underline')}>
                        <Trans>{answer.text}</Trans>
                      </a>
                    </Link>
                  );
                  break;

                case 'list':
                  if (answer.listArray) {
                    text = (
                      <ul key={a}>
                        {answer.listArray.map((list, l) => (
                          <li key={l}>
                            <Trans>{list}</Trans>
                          </li>
                        ))}
                      </ul>
                    );
                  }
                  break;
              }
            }

            return text;
          })}
        </div>
      </div>
    </div>
  );
};

export default FaqItem;
