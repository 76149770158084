import React from 'react';

const PlayTriangle: React.FC = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7 19.9999L18.9999 12L7 4V19.9999Z' fill='white' />
    </svg>
  );
};

export default PlayTriangle;
