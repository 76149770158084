import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { imagesRepeat } from 'helpers/mainSliderImages';

import styles from './ImagesSlider.module.scss';

const ImagesSlider: React.FC = () => {
  const images = imagesRepeat();
  const slider_left = useRef(null);
  const slider_right = useRef(null);
  const [animationWidthLeft, setAnimationWidthLeft] = useState('-50%');
  const [animationWidthRight, setAnimationWidthRight] = useState('-50%');
  const [windowWidth, setWindowWidth] = useState(0);
  const [isAnimate, setIsAnimate] = useState(false);

  // fix ios animation bug
  const resizeHandler = () => {
    if (slider_left.current && slider_right.current) {
      setIsAnimate(false);
      const halfWidthLeft = slider_left.current.offsetWidth / 2;
      const halfWidthRight = slider_right.current.offsetWidth / 2;
      setAnimationWidthLeft(`-${halfWidthLeft}px`);
      setAnimationWidthRight(`-${halfWidthRight}px`);
      setWindowWidth(window.innerWidth);

      setTimeout(() => {
        setIsAnimate(true);
      }, 100);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
      setIsAnimate(false);
    };
  }, [windowWidth, slider_left?.current?.offsetWidth]);

  return (
    <section id='imagesSlider' className={styles.imagesSlider}>
      <style>
        {`:root{
            --images-slider-left-transform-length: ${animationWidthLeft};
            --images-slider-right-transform-length: ${animationWidthRight};
          }`}
      </style>
      <div className={styles.imagesSlider__wrapper}>
        <div
          className={cn(
            styles.imagesSlider__items,
            styles.imagesSlider__itemsToLeft,
            {
              [styles.animation]: isAnimate,
            }
          )}
          ref={slider_left}
        >
          {images.top.map((image, index) => (
            <div
              className={styles.imagesSlider__item}
              key={`${image}-${index}-1`}
            >
              <picture>
                <source
                  srcSet={require(`public/images/imagesSlider/x2/${image}?webp`)}
                  data-srcset={
                    require(`public/images/imagesSlider/${image}?webp`) +
                    ', ' +
                    require(`public/images/imagesSlider/x2/${image}?webp`) +
                    ' 2x'
                  }
                  type='image/webp'
                />
                <img
                  className={cn(styles.imagesSlider__image, 'lazyload')}
                  src='/images/imagesSlider/imagesSlider-placeholder.jpg'
                  data-src={`/images/imagesSlider/x2/${image}`}
                  srcSet={`/images/imagesSlider/${image}, /images/imagesSlider/x2/${image} 2x`}
                  alt={`gallery image ${index + 1}`}
                />
              </picture>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.imagesSlider__wrapper}>
        <div
          className={cn(
            styles.imagesSlider__items,
            styles.imagesSlider__itemsToRight,
            {
              [styles.animation]: isAnimate,
            }
          )}
          ref={slider_right}
        >
          {images.bottom.map((image, index) => (
            <div
              className={styles.imagesSlider__item}
              key={`${image}-${index}-3`}
            >
              <img
                className={cn(styles.imagesSlider__image, 'lazyload')}
                src='/images/imagesSlider/imagesSlider-placeholder.jpg'
                data-src={`/images/imagesSlider/x2/${image}`}
                srcSet={`/images/imagesSlider/${image}, /images/imagesSlider/x2/${image} 2x`}
                alt={`football image ${index + 20}`}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ImagesSlider;
