import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import cn from 'classnames';
import { useIsMobile } from 'helpers/hooks/isMobile';
import { useSelector } from 'react-redux';
import { RootState } from 'stores/store';
import { IUserStore } from 'stores/userStore';
import { useRouter } from 'next/router';
import { FeaturesItemSettings } from 'interfaces/FeaturesSection/FeaturesSection';
import { useImageLang } from 'helpers/hooks/imageLang';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import ModalOrganizers from 'components/blocks/Modal/ModalOrganizers';
import Button from 'components/blocks/Button';
import { Features } from 'components/blocks/Features/Features';
import {
  renderFunctionFromItems,
  stripItemIndexes,
} from 'components/blocks/Features/FeaturesItem';

import styles from './Requirements.module.scss';

const Requirements: React.FC = () => {
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };
  const isExpandedList = currentLanguage === 'en';
  const imageLang = useImageLang();

  const { setModalType } = useContext(AppContext);

  const { t } = useTranslation('homepage');
  const isMobile = useIsMobile(991);
  const userStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = userStored.geoData;
  const isMinimal = geoDataStored !== 'RU' && geoDataStored !== 'US';

  const items: FeaturesItemSettings[] = [
    {
      image: {
        link: '/images/main/requirements-6.png',
        linkRetina: '/images/main/requirements-6-2x.png',
        linkMobile: '/images/main/requirements-6-2x.png',
        alt: 'requirements.items.item1.alt',
      },
      name: `requirements.items.item1.name`,
      text: `requirements.items.item1.text`,
      textMobile: `requirements.items.item1.textMobile`,
      children: (
        <Button
          placeholder={t('requirements.items.item1.button')}
          addStyles={[styles.requirements__itemButton]}
          href='/products/16'
        />
      ),
    },
    {
      image: {
        link: `/images/main/requirements-2${imageLang}.png`,
        linkRetina: `/images/main/requirements-2${imageLang}-2x.png`,
        linkMobile: `/images/main/requirements-2${imageLang}--mobile.png`,
        alt: 'requirements.items.item2.alt',
      },
      name: `requirements.items.item2.name`,
      text: `requirements.items.item2.text`,
      children: (
        <Button
          placeholder={t('requirements.items.item2.button')}
          addStyles={[styles.requirements__itemButton]}
          href='/products/4'
        />
      ),
    },
    {
      image: {
        link: `/images/main/requirements-3${imageLang}.png`,
        linkRetina: `/images/main/requirements-3${imageLang}-2x.png`,
        alt: 'requirements.items.item3.alt',
      },
      name: `requirements.items.item3.name`,
      text: `requirements.items.item3.text`,
      textMobile: `requirements.items.item3.textMobile`,
      children: (
        <Button
          placeholder={t('requirements.items.item3.button')}
          addStyles={[styles.requirements__itemButton]}
          onClick={() => {
            setModalType(MODAL.organizers);
          }}
        />
      ),
    },
    {
      image: {
        link: '/images/main/requirements-4.png',
        linkRetina: '/images/main/requirements-4-2x.png',
        linkMobile: '/images/main/requirements-4--mobile.png',
        alt: 'requirements.items.item4.alt',
      },
      name: `requirements.items.item4.name`,
      text: `requirements.items.item4.text`,
      children: (
        <ul className={styles.requirements__list}>
          {Array.from({ length: isMobile ? 2 : 4 }, (_, i) => {
            const heading = t(
                `requirements.items.item4.items.${i + 1}.heading`
              ),
              text = t(`requirements.items.item4.items.${i + 1}.text`);
            return (
              <li className={styles.requirements__listItem} key={heading}>
                {isMobile ? (
                  <h4 className={styles.requirements__listItemHeading}>
                    {heading}
                  </h4>
                ) : (
                  <h5 className={styles.requirements__listItemHeading}>
                    {heading}
                  </h5>
                )}
                <p className={styles.requirements__listItemText}>
                  <Trans>{text}</Trans>
                </p>
              </li>
            );
          })}
          {isMobile && (
            <li className={styles.requirements__listItem}>
              <h4 className={styles.requirements__listItemHeading}>
                {t(`requirements.items.item4.items.3-4-mobile.heading`)}
              </h4>
              <p className={styles.requirements__listItemText}>
                <Trans>
                  {t(`requirements.items.item4.items.3-4-mobile.text`)}
                </Trans>
              </p>
            </li>
          )}
        </ul>
      ),
    },
    {
      image: {
        link: '/images/main/requirements-5.png',
        linkRetina: '/images/main/requirements-5-2x.png',
        linkMobile: '/images/main/requirements-5--mobile.png',
        alt: 'requirements.items.item5.alt',
      },
      name: `requirements.items.item5.name`,
      text: `requirements.items.item5.text`,
      children: (
        <>
          <ul className={styles.requirements__bulletList}>
            {Array.from({ length: 6 }, (_, i) => {
              const text = t(`requirements.items.item5.items.${i + 1}`);
              return (
                <li className={styles.requirements__bulletListItem} key={text}>
                  {text}
                </li>
              );
            })}
          </ul>
          <Button
            placeholder={t('requirements.items.item5.button')}
            addStyles={[styles.requirements__itemButton]}
            href='/products/14'
          />
        </>
      ),
    },
  ];

  const settings = { t, styles, rootClassName: 'requirements' };
  const propItems = renderFunctionFromItems({
    items: stripItemIndexes(items, isMinimal ? [0, 4] : []),
    settings,
    upLevelHeadings: true,
  });

  return (
    <>
      <section
        id='requirements'
        className={cn(styles.requirements, {
          [styles.requirementsMinimal]: isMinimal,
          [styles.requirementsExpanded]: isExpandedList,
        })}
      >
        <Features
          rootClassName='requirements'
          styles={styles}
          itemsDesktop={propItems}
          itemsMobile={propItems}
          itemsSpecial={[]}
        >
          <div className={styles.requirements__headers}>
            <h2 className={styles.requirements__heading}>
              {t('requirements.title')}
            </h2>
          </div>
        </Features>
      </section>
      <ModalOrganizers />
    </>
  );
};

export default Requirements;
