import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { NewsItem } from 'interfaces/News/News';
import { sortedNews, useGetCurrentNews } from 'helpers/news';
import { useRouter } from 'next/router';

import Link from 'next/link';
import { SwiperSlide } from 'swiper/react';
import Slider from 'components/blocks/Slider/Slider';

import styles from './News.module.scss';
import { mainPageAnchors } from 'helpers/anchors';
import { links } from 'helpers/constants/links';

interface NewsProps {
  showButton?: boolean;
}

const News: FC<NewsProps> = ({ showButton = true }) => {
  const { t } = useTranslation(['news', 'common']);
  const { news } = useGetCurrentNews();

  const currentNews: NewsItem[] = sortedNews(news);
  const router = useRouter();

  const { locale: currentLanguage } = router as {
    locale: string;
  };

  return (
    <section id={mainPageAnchors.news} className={cn(styles.news, 'content')}>
      <Slider
        title={t('news.title')}
        spaceBetween={20}
        breakpoints={{
          480: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            allowTouchMove: currentNews.length > 2,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            allowTouchMove: currentNews.length > 3,
          },
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            allowTouchMove: currentNews.length > 4,
          },
        }}
        className={styles.news__slider}
        showMoreLink={showButton ? `/${links.news}` : ''}
      >
        {currentNews?.map((item, i) => {
          const currentImage = item?.previewImageLocale
            ? item?.previewImageLocale[currentLanguage]
            : item?.previewImage;

          return (
            <SwiperSlide key={i}>
              <Link href={`/${links.news}/${item?.link}`}>
                <a className={cn(styles.news__item, 'link')}>
                  <div className={styles.news__image}>
                    <picture>
                      <source
                        srcSet='/images/news/slider-placeholder.jpg'
                        data-srcset={require(`public/images/news/${currentImage}?webp`)}
                        type='image/webp'
                      />
                      <img
                        src='/images/news/slider-placeholder.jpg'
                        data-src={`/images/news/${currentImage}`}
                        alt={t(`news.items.${item?.id}.title`)}
                        className='img lazyload'
                      />
                    </picture>
                  </div>
                  <div className={styles.news__info}>
                    <div
                      className={cn(styles.news__name, styles.news__nameClamp)}
                    >
                      {t(`news.items.${item?.id}.title`)}
                    </div>
                    <div className={styles.news__date}>
                      {`${t(`commonMonths.${item?.date[1] - 1}`, {
                        ns: 'common',
                      })} ${item?.date[0]}, ${item?.date[2]}`}
                    </div>
                  </div>
                </a>
              </Link>
            </SwiperSlide>
          );
        })}
      </Slider>
    </section>
  );
};

export default News;
