import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';

import styles from './Partners.module.scss';
import Link from 'next/link';
import { mainPageAnchors } from 'helpers/anchors';

import {
  getPartners,
  PartnersItem as IPartnersItem,
} from 'services/partnersService';
import { RootState } from 'stores/store';
import { useSelector } from 'react-redux';
import { IUserStore } from 'stores/userStore';
import { useRouter } from 'next/router';

interface PartnersItemProps {
  children: ReactNode;
  className: string;
  link: string;
}

type PartnersProps = {
  partnersData?: IPartnersItem[];
};

const PartnersItem: React.FC<PartnersItemProps> = ({
  link,
  children,
  className,
}) => {
  if (!link) return <div className={className}>{children}</div>;

  return (
    <Link href={link}>
      <a target='_blank' className={className}>
        {children}
      </a>
    </Link>
  );
};

const Partners: React.FC<PartnersProps> = ({ partnersData }) => {
  const { t } = useTranslation('partners');
  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const track = useRef(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isAnimate, setIsAnimate] = useState(false);

  const [dataDouble, setDataDouble] = useState([
    ...partnersData,
    ...partnersData,
  ]);

  useEffect(() => {
    const partnersItemsResponse = async () => {
      const response = await getPartners({
        locale: currentLanguage,
        geo: geoDataStored,
      });

      if (response?.data?.partners) {
        setDataDouble([
          ...response?.data?.partners,
          ...response?.data?.partners,
        ]);
      }
    };

    partnersItemsResponse();
  }, [geoDataStored, currentLanguage]);

  // fix ios animation bug
  const resizeHandler = () => {
    if (windowWidth !== window.innerWidth && track.current) {
      setIsAnimate(false);
      setWindowWidth(window.innerWidth);

      setTimeout(() => {
        setIsAnimate(true);
      }, 100);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [windowWidth]);

  return (
    <section id={mainPageAnchors.partners} className={styles.partners}>
      <div className='container'>
        <div className={styles.partners__inner}>
          <h2 className={styles.partners__heading}>{t('partners.title')}</h2>
          <div className={styles.partners__trackOuter}>
            <div
              className={cn(styles.partners__track, {
                [styles.animation]: isAnimate,
              })}
              ref={track}
            >
              {partnersData?.length &&
                dataDouble.map(({ name, image_url, link }, i) => (
                  <PartnersItem
                    key={i}
                    className={styles.partners__item}
                    link={link}
                  >
                    <img
                      src={image_url}
                      alt={name}
                      className={cn(styles.partners__image, 'lazyload')}
                    />
                    <div className={styles.partners__text}>{name}</div>
                  </PartnersItem>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
