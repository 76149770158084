import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { amazonLinkMain } from 'helpers/products';
import { useIsMobile } from 'helpers/hooks/isMobile';
import { IUserStore } from 'stores/userStore';
import { RootState } from 'stores/store';
import { storeLinks } from 'helpers/socialLinks';
import { IStoreLink } from 'interfaces/Common/SocialLinks';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Link from 'next/link';
import ScrollIntoView from 'react-scroll-into-view';
import Button from 'components/blocks/Button';
import ModalVideo from 'components/blocks/Modal/ModalVideo';
import PlayTriangle from 'components/blocks/Icons/PlayTriangle';

import styles from './Main.module.scss';
import buttonStyles from 'components/blocks/Button/Button.module.scss';

const StoreLink: React.FC<IStoreLink> = props => {
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  return (
    <Link href={props.link}>
      <a className={styles.main__store} target='_blank'>
        <img
          src={
            '/images/markets/' + currentLanguage + '/' + props.imageNameWhite
          }
          alt={props.alt}
          className='img'
        />
      </a>
    </Link>
  );
};

const Main: React.FC = () => {
  const { t } = useTranslation('homepage');
  const video = useRef(null);
  const [showControls, setShowControls] = useState(false);
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };
  const isMobile = useIsMobile(992);
  const userStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = userStored.geoData;
  const isRussianGeo = geoDataStored === 'RU';

  const [hasProducts, setProducts] = useState(false);
  const { setModalType } = useContext(AppContext);

  useEffect(() => {
    setProducts(geoDataStored === 'US' || geoDataStored === 'RU');
  }, [geoDataStored]);

  useEffect(() => {
    if (!video.current) {
      return;
    }

    video.current.play();
    if (video.current.paused) {
      setShowControls(true);
    }
  }, [video.current]);

  return (
    <>
      <main
        id='main'
        className={cn(styles.main, {
          [styles.mainVideo]: isMobile,
        })}
      >
        {isMobile ? (
          <>
            <div className={styles.main__mobileVideo}>
              <video
                preload='metadata'
                loop
                autoPlay
                muted
                playsInline
                ref={video}
                controls={showControls}
              >
                <source
                  src='/videos/main/output.mp4#t=0.001'
                  type='video/mp4'
                />
              </video>
            </div>
            <div className={styles.main__wrapperVideo}>
              <div className='container'>
                <h1 className={cn('title__main', styles.main__title)}>
                  <Trans>{t('main.title')}</Trans>
                </h1>
                <div
                  className={cn(styles.main__links, {
                    [styles.main__linksAmazon]: geoDataStored === 'US',
                  })}
                >
                  <ScrollIntoView
                    selector={hasProducts ? '#product' : '#tariffs'}
                  >
                    <Button
                      placeholder={t('main.button')}
                      addStyles={[
                        buttonStyles.button__min,
                        styles.main__button,
                        buttonStyles.button__onDark,
                      ]}
                    />
                  </ScrollIntoView>
                  <div className={styles.main__stores}>
                    <StoreLink {...storeLinks.googlePlay} />
                    <StoreLink {...storeLinks.appStore} />
                    <StoreLink {...storeLinks.appGallery} />
                    {isRussianGeo && <StoreLink {...storeLinks.ruStore} />}
                  </div>
                  <Link href={amazonLinkMain}>
                    <a target='_blank' className={styles.main__amazon}>
                      <img
                        src={'/images/amazon.png'}
                        alt='Amazon'
                        className='img'
                      />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.main__wrapper}>
            <div className='container'>
              <div className={styles.main__inner}>
                <div className={styles.main__images}>
                  <div
                    className={styles.main__video}
                    onClick={() => {
                      setModalType(MODAL.video);
                    }}
                  >
                    <picture>
                      <source
                        srcSet={require('public/images/main/video-preview.png?webp')}
                        type='image/webp'
                      />
                      <img
                        src='/images/main/video-preview.png'
                        className='img'
                        alt='idChess video'
                      />
                    </picture>
                    <div className={styles.main__videoButton}>
                      {t('main.video')}
                      <PlayTriangle />
                    </div>
                  </div>
                  <div className={styles.main__chessboard}>
                    <video
                      preload='metadata'
                      loop
                      autoPlay
                      muted
                      playsInline
                      ref={video}
                      controls={showControls}
                      className='img'
                    >
                      <source
                        src='/videos/main-page-video.mp4#t=0.001'
                        type='video/mp4'
                      />
                    </video>
                  </div>
                </div>
                <div
                  className={cn(styles.main__links, styles.main__linksAmazon)}
                >
                  <h1 className={cn('title__main', styles.main__title)}>
                    <Trans>{t('main.title')}</Trans>
                  </h1>
                  <div className={styles.main__storesLinks}>
                    <div className={styles.main__qr}>
                      <img
                        src='/images/main/main-qr.png'
                        alt='QR code'
                        className={styles.main__qrImg}
                      />
                    </div>
                    <div>
                      <p className={styles.main__qrText}>
                        {t('main.download')}
                      </p>
                      <div className={styles.main__stores}>
                        <StoreLink {...storeLinks.googlePlay} />
                        <StoreLink {...storeLinks.appStore} />
                        <StoreLink {...storeLinks.appGallery} />
                        {isRussianGeo && <StoreLink {...storeLinks.ruStore} />}
                      </div>
                      <Link href={amazonLinkMain}>
                        <a target='_blank' className={styles.main__amazon}>
                          <img
                            src='/images/amazon.png'
                            alt='Amazon'
                            className='img'
                          />
                        </a>
                      </Link>
                    </div>
                  </div>
                  <ScrollIntoView
                    selector={hasProducts ? '#product' : '#tariffs'}
                  >
                    <Button
                      placeholder={t('main.button')}
                      addStyles={[
                        buttonStyles.button__min,
                        styles.main__button,
                        buttonStyles.button__onDark,
                      ]}
                    />
                  </ScrollIntoView>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
      <ModalVideo
        video={currentLanguage === 'ru' ? 'tWxIouHGDKs' : 'lwyh9F0sea8'}
        isYoutube={true}
      />
    </>
  );
};

export default Main;
