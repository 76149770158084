import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { ProductItemInterface } from 'interfaces/Product/ProductItem';
import { handleSetErrorText } from 'helpers/hooks/form';
import { links } from 'helpers/constants/links';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import ProductItem from 'components/blocks/Product/ProductItem/ProductItem';
import SeeMoreLink from 'components/blocks/SeeMoreLink';

import styles from './Product.module.scss';
import productStyles from 'components/blocks/Product/ProductMain/ProductMain.module.scss';

interface ProductArray {
  data: ProductItemInterface[];
}

const Product: React.FC<ProductArray> = ({ data }) => {
  const { t } = useTranslation(['homepage', 'feedback']);
  const { setModalType, setModalMessageData } = useContext(AppContext);

  const handleErrorResult = errorMessage => {
    setModalMessageData({
      title: t('feedback.modal.errorModal.title', { ns: 'feedback' }),
      subTitle: handleSetErrorText(errorMessage, t),
      buttonText: t('feedback.modal.errorModal.buttonText', {
        ns: 'feedback',
      }),
    });
    setModalType(MODAL.message);
  };

  return (
    <section id='product' className={styles.product}>
      <div className='container'>
        <div className={styles.product__title}>
          <h2 className='title-main-v2'>{t('product.title')}</h2>
        </div>
        <div
          className={cn(
            productStyles.productMain__items,
            styles.product__items
          )}
        >
          {data.map(item => (
            <ProductItem
              key={item.id}
              image={item.image}
              name={item.name}
              id={item.id}
              term={item.term}
              filters={item.filters}
              stageId={item.stageId}
              amazon={item.amazon}
              setErrorMessage={handleErrorResult}
            />
          ))}
        </div>
        <SeeMoreLink link={`/${links.shop}`} text={t('product.showAll')} />
      </div>
    </section>
  );
};

export default Product;
