import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useIsMobile } from 'helpers/hooks/isMobile';
import cn from 'classnames';
import {
  keepItemIndexes,
  renderFunctionFromItems,
  stripItemIndexes,
} from 'components/blocks/Features/FeaturesItem';
import { genericAnchors } from 'helpers/anchors';
import { useImageLang } from 'helpers/hooks/imageLang';

import { FeaturesItemSettings } from 'interfaces/FeaturesSection/FeaturesSection';
import { Features } from 'components/blocks/Features/Features';

import styles from './Advantages.module.scss';

const useItems = (): FeaturesItemSettings[] => {
  const { t } = useTranslation('homepage');
  const imageLang = useImageLang();

  return [
    {
      image: {
        link: `/images/main/advantages-1${imageLang}.png`,
        linkMobile: `/images/main/advantages-1${imageLang}.png`,
        linkRetina: `/images/main/advantages-1${imageLang}-2x.png`,
        alt: 'advantages.items.item1.alt',
      },
      name: `advantages.items.item1.name`,
      nameMobile: `advantages.items.item1.nameMobile`,
      text: `advantages.items.item1.text`,
      textMobile: `advantages.items.item1.textMobile`,
    },
    {
      image: {
        link: `/images/main/advantages-2${imageLang}.png`,
        linkMobile: `/images/main/advantages-2${imageLang}--mobile.png`,
        linkRetina: `/images/main/advantages-2${imageLang}-2x.png`,
        alt: 'advantages.items.item2.alt',
      },
      name: `advantages.items.item2.name`,
      text: `advantages.items.item2.text`,
    },
    {
      image: {
        link: `/images/main/advantages-3${imageLang}.png`,
        linkMobile: `/images/main/advantages-3${imageLang}--mobile.png`,
        linkRetina: `/images/main/advantages-3${imageLang}-2x.png`,
        alt: 'advantages.items.item3.alt',
      },
      name: `advantages.items.item3.name`,
      nameMobile: `advantages.items.item3.nameMobile`,
      text: `advantages.items.item3.text`,
      textMobile: `advantages.items.item3.textMobile`,
    },
    {
      image: {
        link: '/images/main/advantages-5.png',
        linkMobile: '/images/main/advantages-5--mobile.png',
        alt: 'advantages.items.item4.alt',
      },
      name: `advantages.items.item4.name`,
      text: `advantages.items.item4.text`,
      textMobile: `advantages.items.item4.textMobile`,
      link: '/guide',
      children: (
        <img
          className={styles.advantages__itemArrow}
          src='/images/main/arrow-right.svg'
          alt={t('advantages.items.item4.arrowAlt')}
        />
      ),
    },
    {
      image: {
        link: `/images/main/advantages-4${imageLang}.png`,
        linkMobile: `/images/main/advantages-4${imageLang}.png`,
        linkRetina: `/images/main/advantages-4${imageLang}-2x.png`,
        alt: 'advantages.items.item5.alt',
      },
      name: `advantages.items.item5.name`,
      text: `advantages.items.item5.text`,
    },
  ];
};

const AdvantagesMobileItem: React.FC<{ index: number }> = ({ index }) => {
  const { t } = useTranslation('homepage');
  const items = useItems();

  return (
    <li
      className={cn(
        styles.advantages__itemMin,
        styles[`advantages__itemMin--${index + 1}`]
      )}
    >
      <img
        src={items[index].image.link.replace('.png', '--min.png')}
        className={styles.advantages__itemImageMinimal}
        alt={t(items[index].image.alt)}
      />
      <h3 className={styles.advantages__itemHeadingMin}>
        <Trans>{t(`advantages.items.item${index + 1}.textMin`)}</Trans>
      </h3>
    </li>
  );
};

const Advantages: React.FC = () => {
  const { t } = useTranslation('homepage');
  const isMinimallyMobile = useIsMobile(767);
  const settings = { t, styles, rootClassName: 'advantages' };
  const items = useItems();

  const desktopItems = renderFunctionFromItems({ items, settings });
  const itemsMin = [
    ...renderFunctionFromItems({
      items: stripItemIndexes(items, [3]),
      Component: AdvantagesMobileItem,
      settings,
    }),
    ...renderFunctionFromItems({
      items: keepItemIndexes(items, [3]),
      settings,
    }),
  ];

  return (
    <section id={genericAnchors.advantages} className={styles.advantages}>
      {isMinimallyMobile ? (
        <Features
          rootClassName='advantages'
          styles={styles}
          itemsDesktop={desktopItems}
          itemsMobile={[]}
          itemsSpecial={itemsMin}
        >
          <div className={styles.advantages__headers}>
            <h2 className={styles.advantages__heading}>
              {t('advantages.title')}
            </h2>
          </div>
        </Features>
      ) : (
        <Features
          rootClassName='advantages'
          styles={styles}
          itemsDesktop={desktopItems}
          itemsMobile={[]}
          itemsSpecial={desktopItems}
        >
          <div className={styles.advantages__headers}>
            <h2 className={styles.advantages__heading}>
              {t('advantages.title')}
            </h2>
            <h3 className={styles.advantages__subheading}>
              {t('advantages.subtitle')}
            </h3>
          </div>
        </Features>
      )}
    </section>
  );
};

export default Advantages;
