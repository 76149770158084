const images = {
  top: [
    'imagesSlider__img2.png',
    'imagesSlider__img3.png',
    'imagesSlider__img4.png',
    'imagesSlider__img5.png',
    'imagesSlider__img6.png',
    'imagesSlider__img7.png',
  ],
  bottom: [
    'imagesSlider__img8.png',
    'imagesSlider__img9.png',
    'imagesSlider__img10.png',
    'imagesSlider__img11.png',
    'imagesSlider__img12.png',
    'imagesSlider__img13.png',
    'imagesSlider__img14.png',
    'imagesSlider__img15.png',
    'imagesSlider__img16.png',
  ],
};

const repeat = 2;

export const imagesRepeat = () => {
  let imagesFinal = {
    top: [],
    bottom: [],
  };

  for (let i = 0; i < repeat; i++) {
    imagesFinal.top = imagesFinal.top.concat(images.top);
    imagesFinal.bottom = imagesFinal.bottom.concat(images.bottom);
  }

  return imagesFinal;
};
