import React, { useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { OrganizersFeedbackData } from 'interfaces/Feedback/Feedback';
import { submitOrganizersFeedback } from 'services/organizerPageService';
import { handleSetErrorText } from 'helpers/hooks/form';
import { useRouter } from 'next/router';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Modal from '../Modal';
import FeedbackForm from 'components/blocks/FeedbackForm';
import Button from 'components/blocks/Button';
import Link from 'next/link';

import styles from './ModalOrganizers.module.scss';

interface ModalOrganizersLinkProps {
  link: string;
}

const ModalOrganizersLink: React.FC<ModalOrganizersLinkProps> = ({
  children,
  link,
}) => {
  return (
    <Link href={link}>
      <a
        target='_blank'
        rel='noopener noreferrer'
        className='link link-underline text-green'
      >
        {children}
      </a>
    </Link>
  );
};

const ModalOrganizers: React.FC = () => {
  const { t } = useTranslation('modal');
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const { modalType, setModalType, setModalMessageData } =
    useContext(AppContext);

  const handleSuccessResult = () => {
    setModalMessageData({
      title: t('feedback.modal.successModal.title', { ns: 'feedback' }),
      subTitle: t('feedback.modal.successModal.subTitle', { ns: 'feedback' }),
      buttonText: t('feedback.modal.successModal.buttonText', {
        ns: 'feedback',
      }),
    });
    setModalType(MODAL.success);
  };

  const handleErrorResult = errorMessage => {
    setModalMessageData({
      title: t('feedback.modal.errorModal.title', { ns: 'feedback' }),
      subTitle: handleSetErrorText(errorMessage, t),
      buttonText: t('feedback.modal.errorModal.buttonText', {
        ns: 'feedback',
      }),
    });
    setModalType(MODAL.message);
  };

  const handleSubmit = useCallback(
    async (data: OrganizersFeedbackData, actions) => {
      setModalType('');
      submitOrganizersFeedback(data, actions, {
        language: currentLanguage,
        successCallback: handleSuccessResult,
        errorCallback: handleErrorResult,
      });
    },
    [currentLanguage]
  );

  const items = t('modal.organizers.items', { returnObjects: true });

  if (!Array.isArray(items) || !items?.length) return null;

  return (
    <>
      <Modal
        isOpen={modalType === MODAL.organizers}
        additionalClasses={[styles.modal]}
      >
        <FeedbackForm
          page='organize'
          image={
            <ul className={styles.modal__points}>
              {items.map((item, i) => (
                <li className={styles.modal__point} key={item.text}>
                  <span>
                    <Trans
                      t={t}
                      i18nKey={`modal.organizers.items.${i}.text`}
                      components={item.links.map((link, i) => (
                        <ModalOrganizersLink key={i} link={link} />
                      ))}
                    />
                  </span>
                </li>
              ))}
            </ul>
          }
          content={{
            heading: t('modal.organizers.title'),
            subheading: t('modal.organizers.subtitle'),
            subheadingWithinForm: true,
          }}
          buttonText={t('modal.organizers.submit')}
          onSubmit={handleSubmit}
          additionalClass={'modalForm'}
          additionalStyles={styles}
          additionalButton={
            <Button
              placeholder={t('modal.organizers.cancel')}
              type='button'
              addStyles={[
                styles.modalForm__button,
                styles.modalForm__buttonGray,
              ]}
              onClick={() => setModalType('')}
            />
          }
        />
        <div className='closeButton' onClick={() => setModalType('')} />
      </Modal>
    </>
  );
};

export default ModalOrganizers;
