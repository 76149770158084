import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { MediaItem } from 'services/homepageService';

import Link from 'next/link';
import { SwiperSlide } from 'swiper/react';
import Slider from 'components/blocks/Slider/Slider';

import styles from './Media.module.scss';
import newsStyles from 'components/blocks/News/News.module.scss';
import { mainPageAnchors } from 'helpers/anchors';
import { links } from 'helpers/constants/links';

interface MediaProps {
  data: MediaItem[];
  title?: string;
  showButton?: boolean;
}

const Media: FC<MediaProps> = ({ data, title, showButton = true }) => {
  const { t } = useTranslation(['media', 'common']);

  return (
    <section id={mainPageAnchors.media} className={cn(styles.media, 'content')}>
      <Slider
        title={title ? title : t('media.title')}
        spaceBetween={20}
        breakpoints={{
          480: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        }}
        className={newsStyles.news__slider}
        showMoreLink={showButton ? `/${links.news}` : ''}
      >
        {data.map((item, i) => {
          const text = t(`media.items.${item?.id}.text`, { ns: 'media' });

          return (
            <SwiperSlide key={i}>
              <Link href={item.link}>
                <a className={cn(newsStyles.news__item, 'link')}>
                  <div className={newsStyles.news__image}>
                    <picture>
                      <source
                        srcSet='/images/news/image-placeholder.jpg'
                        data-srcset={require(`public/images/media/${item.image}?webp`)}
                        type='image/webp'
                      />
                      <img
                        src='/images/news/image-placeholder.jpg'
                        srcSet={`/images/media/${item.image}`}
                        alt={item.name}
                        className='img lazyload'
                      />
                    </picture>
                  </div>
                  <div className={newsStyles.news__info}>
                    <div
                      className={cn(
                        styles.media__name,
                        styles.media__nameClamp
                      )}
                    >
                      {item.name}
                    </div>
                    <div
                      className={cn(
                        styles.media__text,
                        styles.media__textClamp
                      )}
                    >
                      {text}
                    </div>
                    <div className={styles.media__bottom}>
                      <div className={newsStyles.news__date}>
                        {`${t(`commonMonths.${item?.date[1] - 1}`, {
                          ns: 'common',
                        })} ${item?.date[0]}, ${item?.date[2]}`}
                      </div>
                      <div className={styles.media__bottomImages}>
                        <div className={styles.media__logo}>
                          <img
                            src='/images/media/media-logo-placeholder.png'
                            data-src={`/images/media/${item.logo}`}
                            alt={`logo`}
                            className={cn('lazyload', 'img')}
                          />
                        </div>
                        <div className={styles.media__flag}>
                          <img
                            src='/images/media/media-flag-placeholder.png'
                            data-src={`/images/media/flags/${item.flag}`}
                            alt={`flag`}
                            className={cn('lazyload', 'img')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </Link>
            </SwiperSlide>
          );
        })}
      </Slider>
    </section>
  );
};

export default Media;
