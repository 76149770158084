import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';

type PartnersDataResponse = {
  partners: PartnersItem[];
};

export interface PartnersItem {
  id: number;
  image_url: string;
  name: string;
  link: string;
}

export const getPartners = async ({
  locale,
  geo,
}): Promise<AxiosResponse<PartnersDataResponse>> => {
  try {
    const response = await httpClient.get<PartnersDataResponse>('/partners', {
      params: {
        locale,
        geo,
      },
    });
    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};
