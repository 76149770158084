export const mediaImages = [
  {
    image: 'media__img-22.png',
    logo: 'logo-prochess.svg',
    link: 'https://prochess.org/idchess-computer-vision-technology/',
    id: 'proChess',
    date: [25, 12, 2023],
    flag: 'lebanon.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-21.png',
    logo: 'logo-youtube.svg',
    link: 'https://www.youtube.com/watch?v=CgSU7QkOiMc',
    id: 'chessDoctor',
    date: [20, 12, 2023],
    flag: 'egypt.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-16.jpg',
    logo: 'logo-india.png',
    link: 'https://chessbase.in/news/New-technology-in-Chess-idChess',
    id: 'india_ready',
    date: [26, 7, 2023],
    flag: 'india.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-17.jpg',
    logo: 'logo-kommersant.svg',
    link: 'https://www.kommersant.ru/doc/6082790',
    id: 'var_kommersant',
    date: [3, 7, 2023],
    flag: 'russia.svg',
    filters: ['var'],
  },
  {
    image: 'media__img-18.jpg',
    logo: 'logo-tv2.svg',
    link: 'https://www.tv2.no/direkte/0nr51/sportsdognet-live/64a28779572197c82940708a/var-innfores-i-sjakk',
    id: 'var_tv2',
    date: [3, 7, 2023],
    flag: 'norway.svg',
    filters: ['var'],
  },
  {
    image: 'media__img-19.jpg',
    logo: 'logo-tvpsport.png',
    link: 'https://sport.tvp.pl/71019034/system-var-zadebiutowal-w-rywalizacji-szachistow/amp',
    id: 'var_sport',
    date: [3, 7, 2023],
    flag: 'poland.svg',
    filters: ['var'],
  },
  {
    image: 'media__img-20.jpg',
    logo: 'logo-tass.svg',
    link: 'https://tass.ru/sport/18174593/amp',
    id: 'var_tass',
    date: [3, 7, 2023],
    flag: 'russia.svg',
    filters: ['var'],
  },
  {
    image: 'media__img-13.jpg',
    logo: 'logo-match.png',
    link: 'https://www.youtube.com/watch?v=mhQ89cHZC1g&t=517s',
    id: 'match',
    date: [17, 4, 2023],
    flag: 'kazakhstan.svg',
    filters: ['tournaments'],
  },
  {
    image: 'media__img-12.jpg',
    logo: 'logo-kazakhstan-today.png',
    link: 'https://www.kt.kz/rus/high-tech/novye_tehnologii_v_shahmatah_neyroset_transliruet_match_1377948887.html',
    id: 'kazakhstan_today',
    date: [12, 4, 2023],
    flag: 'kazakhstan.svg',
    filters: ['tournaments', 'about'],
  },
  {
    image: 'media__img-14.jpg',
    logo: 'logo-mir-24.png',
    link: 'https://mir24.tv/news/16548433/ot-shahmatnyh-korolei-do-yunyh-grossmeisterov-kak-prohodit-chempionat-mira-po-shahmatam-v-astane',
    id: 'mir24',
    date: [10, 4, 2023],
    flag: 'kazakhstan.svg',
    filters: ['tournaments'],
  },
  {
    image: 'media__img-15.jpg',
    logo: 'logo-prosports-kz.png',
    link: 'https://prosports.kz/news/594453-neiroset-otsifruet-turniry-v-ramkah-matcha-za-zvanie-chempiona-mira-v-kazahstane',
    id: 'prosports_kz',
    date: [10, 4, 2023],
    flag: 'kazakhstan.svg',
    filters: ['tournaments'],
  },
  {
    image: 'media__img-11.jpg',
    logo: 'logo-africa.png',
    link: 'https://africachessmedia.com/new-chess-technology-idchess-the-platform-for-recognizing-broadcasting-chess-games/',
    id: 'africa',
    date: [14, 12, 2022],
    flag: 'africa.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-4.png',
    logo: 'logo-chesscom.png',
    link: 'https://www.chess.com/blog/idChess_Club/evolution-of-chess-ai-from-turochamp-to-idchess',
    id: 'chesscom',
    date: [18, 10, 2022],
    flag: 'usa.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-2.png',
    logo: 'logo-life.png',
    link: 'https://lifehacker.ru/igra-v-shaxmaty-s-pomoshhyu-texnologij/',
    id: 'lifehacker',
    date: [6, 10, 2022],
    flag: 'russia.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-1.png',
    logo: 'logo-skillbox.png',
    link: 'https://skillbox.ru/media/growth/istoriya-shakhmat-ot-avtomatafokusnika-do-otsifrovki-partiy-iskusstvennym-intellektom/',
    id: 'skillbox',
    date: [20, 7, 2022],
    flag: 'russia.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-3.png',
    logo: 'logo-hitech.png',
    link: 'https://hightech.fm/2022/05/20/chess-tech',
    id: 'hightech',
    date: [20, 5, 2022],
    flag: 'russia.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-5.png',
    logo: 'logo-mel.png',
    link: 'https://mel.fm/blog/marianna-yudina/61738-kak-podderzhat-yunogo-shakhmatista-na-pervykh-turnirakh',
    id: 'mel',
    date: [22, 5, 2022],
    flag: 'russia.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-6.png',
    logo: 'logo-russia-1.png',
    link: 'https://smotrim.ru/video/2263471',
    id: 'russia',
    date: [21, 1, 2021],
    flag: 'russia.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-7.png',
    logo: 'logo-letidor.png',
    link: 'https://letidor.ru/obrazovanie/5-poleznykh-servisov-dlya-obucheniya-rebenka-igre-v-shakhmaty.htm',
    id: 'letidor',
    date: [30, 3, 2021],
    flag: 'russia.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-8.png',
    logo: 'logo-7ya.png',
    link: 'https://www.7ya.ru/article/Gde-igrat-v-shahmaty-onlajn-6-luchshih-sajtov/',
    id: '7ya',
    date: [18, 11, 2020],
    flag: 'russia.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-10.png',
    logo: 'logo-sports.png',
    link: 'https://techfusion.ru/rossijskij-startap-predstavil-platformu-dlya-igry-v-shahmaty-idchess/',
    id: 'techfusion',
    date: [13, 12, 2019],
    flag: 'russia.svg',
    filters: ['about'],
  },
  {
    image: 'media__img-9.png',
    logo: 'logo-sapo.png',
    link: 'https://desporto.sapo.pt/geral/artigos/idchess-a-startup-que-quer-digitalizar-os-seus-moves-de-xadrez',
    id: 'sapo',
    date: [6, 11, 2019],
    flag: 'portugal.svg',
    filters: ['about'],
  },
];
