import React from 'react';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';
import { useIsMobile } from 'helpers/hooks/isMobile';

import styles from './Organizers.module.scss';
import Link from 'next/link';
import ArrowRightIcon from 'components/blocks/Icons/Arrows/ArrowRight';
import ImagesSlider from '../ImagesSlider';

interface OrganizersImageProps {
  index: number;
  classes?: string[];
}

const OrganizersImage = ({ index, classes = [] }: OrganizersImageProps) => {
  const { t } = useTranslation('homepage');

  return (
    <div className={cn(styles.organizers__image, ...classes)}>
      <img
        className={styles.organizers__imageInner}
        src={`/images/main/organizers/${index}.jpg`}
        alt={t(`organizers.imageAlts.${index}`)}
      />
    </div>
  );
};

const Organizers: React.FC = () => {
  const { t } = useTranslation('homepage');
  const isMobile = useIsMobile(1280);

  const imageClasses = [
    [styles.organizers__imageTop],
    [],
    [],
    [styles.organizers__imageBottom],
    [],
    [],
    [styles.organizers__imageBottom],
    [],
    [],
    [styles.organizers__imageBottom],
    [styles.organizers__imageTop],
    [],
    [styles.organizers__imageBottom],
  ];

  return (
    <section id='organizers' className={styles.organizers}>
      <div className={cn('container', styles.organizers__inner)}>
        <div className={styles.organizers__headers}>
          <h2 className={styles.organizers__heading}>{t('organizers.text')}</h2>
          {!isMobile && (
            <h3 className={styles.organizers__subheading}>
              {t('organizers.title')}
            </h3>
          )}
        </div>
        {isMobile && <ImagesSlider />}
        <Link href='/organizers'>
          <a className={styles.organizers__link}>
            {t('organizers.button')}
            <ArrowRightIcon />
          </a>
        </Link>
        {!isMobile && (
          <div className={styles.organizers__images}>
            {imageClasses.map((classes, index) => (
              <OrganizersImage
                key={index}
                classes={classes}
                index={index + 1}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Organizers;
