import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { NewsItem } from 'interfaces/News/News';
import { sortedArticles, useGetCurrentArticles } from 'helpers/blog';
import { useRouter } from 'next/router';

import Link from 'next/link';
import { SwiperSlide } from 'swiper/react';
import Slider from 'components/blocks/Slider/Slider';

import newsStyles from 'components/blocks/News/News.module.scss';
import { mainPageAnchors } from 'helpers/anchors';
import { links } from 'helpers/constants/links';

interface BlogProps {
  showButton?: boolean;
}

const Blog: FC<BlogProps> = ({ showButton = true }) => {
  const { t } = useTranslation(['blog', 'common']);
  const { articles } = useGetCurrentArticles();

  const currentArticles: NewsItem[] = sortedArticles(articles);
  const router = useRouter();

  const { locale: currentLanguage } = router as {
    locale: string;
  };

  return (
    <section id={mainPageAnchors.blog} className='content'>
      <Slider
        title={t('blog.title')}
        spaceBetween={20}
        breakpoints={{
          480: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        }}
        className={newsStyles.news__slider}
        showMoreLink={showButton ? `/${links.blog}` : ''}
      >
        {currentArticles?.map((item, i) => {
          const currentImage = item?.previewImageLocale
            ? item?.previewImageLocale[currentLanguage]
            : item?.previewImage;

          return (
            <SwiperSlide key={i}>
              <Link href={`/${links.blog}/${item?.link}`}>
                <a className={cn(newsStyles.news__item, 'link')}>
                  <div className={newsStyles.news__image}>
                    <picture>
                      <source
                        srcSet='/images/news/slider-placeholder.jpg'
                        data-srcset={require(`public/images/blog/${currentImage}?webp`)}
                        type='image/webp'
                      />
                      <img
                        src='/images/news/slider-placeholder.jpg'
                        data-src={`/images/blog/${currentImage}`}
                        alt={t(`blog.items.${item?.id}.title`)}
                        className='img lazyload'
                      />
                    </picture>
                  </div>
                  <div className={newsStyles.news__info}>
                    <div
                      className={cn(
                        newsStyles.news__name,
                        newsStyles.news__nameClamp
                      )}
                    >
                      {t(`blog.items.${item?.id}.title`)}
                    </div>
                    <div className={newsStyles.news__date}>
                      {`${t(`commonMonths.${item?.date[1] - 1}`, {
                        ns: 'common',
                      })} ${item?.date[0]}, ${item?.date[2]}`}
                    </div>
                  </div>
                </a>
              </Link>
            </SwiperSlide>
          );
        })}
      </Slider>
    </section>
  );
};

export default Blog;
