import React, { useMemo } from 'react';
import { useTranslation, Trans } from 'next-i18next';
import cn from 'classnames';
import { useIsMobile } from 'helpers/hooks/isMobile';
import { prettifyNum } from 'helpers/hooks/prettifyNum';
import { tariffsItems, tariffsProps } from 'helpers/tariffsItems';
import { productPrices, currency } from 'helpers/products';
import { useSelector } from 'react-redux';
import { IUserStore } from 'stores/userStore';
import { RootState } from 'stores/store';

import Button from 'components/blocks/Button';

import styles from './Tariffs.module.scss';
import stylesButton from 'components/blocks/Button/Button.module.scss';
import { mainPageAnchors } from 'helpers/anchors';

interface TariffsPricesItem {
  id: number;
}

interface TariffsPrices {
  data: TariffsPricesItem[];
}

interface TariffsPropsItems {
  [key: string]: string[];
}

const Tariffs: React.FC<TariffsPrices> = ({ data }: TariffsPrices) => {
  const { t } = useTranslation('homepage');
  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;
  const currencyLocale = geoDataStored === 'RU' ? 'ru' : 'en';

  const currentPrices = useMemo(() => {
    const pricesItems = data.map(item => {
      let type = '';

      switch (item.id.toString()) {
        case '2':
          type = '1_month';
          break;

        case '4':
          type = '1_year';
          break;
      }

      return {
        type: type,
        price: productPrices[currencyLocale][item.id].price,
      };
    });

    pricesItems.unshift({
      type: 'free',
      price: '0',
    });

    return pricesItems;
  }, [data]);

  const tariffsPropsItems: TariffsPropsItems = tariffsItems,
    currentTariffsProps: string[] = tariffsProps;

  const isMobile = useIsMobile(768);

  const buttonLinkMobile = type => {
    let link = '#';

    switch (type) {
      case 'free':
        link = process.env.NEXT_PUBLIC_API_HOST + '/app';
        break;
      case '1_month':
        link = '/products/2';
        break;
      case '1_year':
        link = '/products/4';
        break;
    }

    return link;
  };

  return (
    <section id={mainPageAnchors.tariffs} className={styles.tariffs}>
      <div className='container'>
        <div className={styles.tariffs}>
          {isMobile && (
            <h2 className={styles.tariffs__heading}>
              <Trans>{t('tariffs.title')}</Trans>
            </h2>
          )}
          <div className={styles.tariffs__inner}>
            {!isMobile && (
              <h2 className={styles.tariffs__heading}>
                <Trans>{t('tariffs.title')}</Trans>
              </h2>
            )}
            {currentPrices.map((item, index) => (
              <div
                key={index}
                className={cn(styles.tariffs__types, {
                  [styles.tariffs__typesProfit]: item.type === '1_year',
                })}
              >
                {item.type === '1_year' && (
                  <div className={styles.tariffs__profit}>
                    {t('tariffs.profit_1')} 50% {t('tariffs.profit_2')}
                  </div>
                )}
                <div className={styles.tariffs__name}>
                  <Trans>{t(`tariffs.${item.type}.title`)}</Trans>
                </div>
                <div className={styles.tariffs__price}>
                  {prettifyNum(item.price)} {currency[currencyLocale].sign}
                </div>
              </div>
            ))}
            {currentTariffsProps.map((prop, p) => (
              <React.Fragment key={`prop ${p}`}>
                <div
                  className={cn(styles.tariffs__prop, {
                    [styles.tariffs__cellFirst]: p === 0,
                  })}
                >
                  <div className={styles.tariffs__propText}>
                    <Trans>{t(`tariffs.${prop}`)}</Trans>
                  </div>
                </div>
                {currentPrices.map((item, i) => {
                  const isCheck = tariffsPropsItems[item.type]
                    ? tariffsPropsItems[item.type].indexOf(prop) !== -1
                    : false;
                  return (
                    <div
                      key={i}
                      className={cn(styles.tariffs__check, {
                        [styles.tariffs__cellFirst]: p === 0,
                      })}
                    >
                      <img
                        src={`/images/icons/${
                          isCheck ? 'tariffs-check.svg' : 'tariffs-cross.svg'
                        }`}
                        alt='check'
                        className={styles.tariffs__checkImg}
                      />
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
            {!isMobile && <div className={styles.tariffs__cellLast} />}
            {currentPrices?.map((item, index) => (
              <div
                key={index}
                className={cn(styles.tariffs__button, styles.tariffs__cellLast)}
              >
                <Button
                  placeholder={
                    index === 0 ? t('tariffs.try') : t('tariffs.buy')
                  }
                  href={buttonLinkMobile(item.type)}
                  addStyles={[
                    cn(stylesButton.button__tariffs, {
                      [stylesButton.button__transparent]:
                        index !== currentPrices.length - 1,
                    }),
                  ]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tariffs;
